<template>
    <Popper hover :placement="placement">
        <slot />
        <template #content>
            <span v-html="title"></span>
        </template>
    </Popper>
</template>

<script>
import Popper from "vue3-popper";

export default {
    components: {
        Popper
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        placement: {
            type: String,
            default: "top"
        }
    },
    data() {
        return {
        }
    },
    watch: {
    }
}
</script>

<style>
.popper {
    pointer-events: none;
}
  :root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 6px 12px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>