<template>
    <Layout :auth='auth'>
        <Head :title="user.name" />
        <template v-slot:title>
            <div class="flex items-center gap-x-4">
                <avatar v-if="user" :size='12' :user='user' />
                {{ user.name }}
                <div>
                    <span v-if="!user.is_active" class="bg-red-700 text-white py-2 px-4 rounded-lg text-sm">Inactive</span>
                </div>
            </div>
        </template>
        <template v-slot:actions>
            <btn class="mr-4" green @click="spoof" v-if="userCan.spoof">
                <i class="fa fa-spinner-third fa-spin" v-if="spoofing"></i>
                Sign In As User
            </btn>
            <btn white :href="$route('users.edit', user.id)" v-if="userCan.update">
                Edit User
            </btn>
        </template>

        <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                <div class="flex items-center flex-wrap">
                    <span class="flex-grow">
                        User Information
                        <span
                            v-if='!user.is_active'
                            class="bg-red-500 text-white px-3 py-1 rounded ml-2"
                        >Inactive</span>
                    </span>
                </div>
            </h3>
        </div>
        <div class="px-4 py-5 sm:p-0">
            <dl>
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    v-if="user.name"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        User Type
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ user.type ? user.type.charAt(0).toUpperCase() + user.type.slice(1) : '--' }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="user.client"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Client
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ user.client.name }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="user.name"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Name
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ user.name }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                    v-if="user.email"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Email
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ user.email }}
                    </dd>
                </div>
                <div
                    v-if="user.type == 'team'"
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Teams
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ (user.teams || []).join(", ") }}
                    </dd>
                </div>
                <div
                    v-if="user.type == 'team'"
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Default Work Type
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ typeNames[user.default_work_type] || '--' }}
                    </dd>
                </div>
                <div
                    class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
                >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                        Permissions
                    </dt>
                    <dd
                        class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                        {{ permissions(user.permissions) }}
                    </dd>
                </div>
            </dl>
        </div>
    </Layout>
</template>

<script>
import avatar from "../../Common/avatar.vue"
import Layout from "../../Common/Layout.vue"
import btn from "../../Common/btn.vue"
import auth from '../../Common/Mixins/auth'
import { Head } from '@inertiajs/vue3'

export default {
    components: { avatar, Layout, Head, btn },
    mixins: [auth],
    props: {
        auth: Object,
        types: Array,
        user: {
            type: Object,
            default: null
        },
        availablePermissions: Array
    },
    data() {
        return {
            spoofing: false,
            archiving: false,
            userCan: {
                spoof: false,
                update: false,
                delete: false
            }
        }
    },
    async mounted() {
        this.userCan.spoof = await this.can('spoof', 'user', this.user.id)
        this.userCan.update = await this.can('update', 'user', this.user.id)
        this.userCan.delete = await this.can('delete', 'user', this.user.id)
    },
    computed: {
        typeNames() {
            const names = {}
            this.types.forEach((type) => {
                names[type.slug] = type.name
            })
            return names
        }
    },
    methods: {
        permissions (perms) {
            const map = {}
            this.availablePermissions.forEach(p => map[p.key] = p.title)
            const str = (perms || []).map(p => map[p]).join(', ')
            return str || "No permissions"
        },
        spoof() {
            if (confirm("Are you sure you want to login as this user?")) {
                this.spoofing = true
                this.$inertia.visit(this.$route("users.spoof", this.user.id))
            }
        },
        team(team) {
            return team
                ? {
                      design: "Design",
                      dev: "Development",
                      ateam: "A-Team",
                      admin: "Admin",
                  }[team]
                : ""
        }
    }
}
</script>

<style lang="postcss" scoped></style>
